
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "pinyin": {
                "type": "string",
                "ui": {
                    "label": "pinyin"
                }
            },

            "title": {
                "type": "string",
                "ui": {
                    "label": "title"
                }
            },
            "thumb": images({}),


            "description": {
                "type": "string",
                "ui": {
                    "label": "description"
                }
            },




        }
    }